// Carousel.js
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { FaArrowCircleRight } from "react-icons/fa";
import '../styles/Carousel.css';
import image1 from '../assets/carousel/image1.jpg'
import image1_webp from '../assets/carousel/image1.webp'
import image2 from '../assets/carousel/image2.jpg'
import image2_webp from '../assets/carousel/image2.webp'
import image3 from '../assets/carousel/image3.jpg'
import image3_webp from '../assets/carousel/image3.webp'
import image4 from '../assets/carousel/image4.jpg'
import image4_webp from '../assets/carousel/image4.webp'
import image5 from '../assets/carousel/image5.jpg'
import image5_webp from '../assets/carousel/image5.webp'

export const Carousel = () => {
    const items = [
        { id: 1, title: "Filmmaker", image: image1, webp: image1_webp },
        { id: 2, title: "Advertiser", image: image2, webp: image2_webp },
        { id: 3, title: "Interface Designer", image: image3, webp: image3_webp },
        { id: 4, title: "User Experience", image: image4, webp: image4_webp },
        { id: 5, title: "Game Developer", image: image5, webp: image5_webp },
        // Add more items as needed
    ];

    return (
        <Swiper
            spaceBetween={20} // Space between slides
            slidesPerView={3} // Default for larger screens
            centeredSlides={true} // Center slides
            initialSlide={Math.floor(items.length / 2)} // Start at the middle slide
            breakpoints={{
                640: { slidesPerView: 1 },   // 1 item for smaller screens
                768: { slidesPerView: 2 },   // 2 items for tablets
                1024: { slidesPerView: 3 },  // 3 items for medium screens
                1280: { slidesPerView: 4 },  // 4 items for large screens
                1536: { slidesPerView: 5 },  // 5 items for extra-large screens
                1920: { slidesPerView: 6 },  // 6 items for very large screens
            }}
            style={{ width: '100%', overflow: 'hidden' }}  // Full width and hide overflow
        >
            {items.map(item => (
                <SwiperSlide key={item.id}>
                    <ClickableCard title={item.title} image={item.image} />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

const ClickableCard = ({ title, image, webp }) => {
    const handleClick = () => {
        // Add your click handling logic here (e.g., navigate to a page)
        //console.log("Card clicked: " + title);
    };

    return (
        <div className="clickableCard" onClick={handleClick}>
            <img
                src={webp}
                alt={title}
                className="cardImage"
                onError={(e) => {
                    e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
                    e.currentTarget.src = image; // Fallback to the original JPG or PNG
                }}
            />

            <hr className="carouselDivider" />
            <div className="cardFooter">
                <span className="cardTitle">{title}</span>
                <FaArrowCircleRight className="arrowIcon" />
            </div>
        </div>
    );
};
