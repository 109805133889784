// app/routes/home.tsx
import {
  HeadersFunction,
  json,
  LoaderFunctionArgs,
  MetaFunction,
  redirect,
} from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';
import { useState } from 'react';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { CgPlayButtonO } from 'react-icons/cg';
import { FaCircleCheck } from 'react-icons/fa6';
import { Carousel } from '~/components/Carousel';
import { Footer } from '~/components/Footer';
import { Header } from '~/components/Header';
import '~/styles/home.css';
import analyticsMix from '../assets/analyticsmix.png';
import bskLogo from '../assets/bsklogo.png';
import dynamicInsights1 from '../assets/dynamicinsights_1.png';
import dynamicInsights2 from '../assets/dynamicinsights_2.png';
import deviceImage from '../assets/herosection.png';
import falseVideo from '../assets/falsevideo.png';

import analyticsMix_webp from '../assets/analyticsmix.webp';
import bskLogo_webp from '../assets/bsklogo.webp';
import dynamicInsights1_webp from '../assets/dynamicinsights_1.webp';
import dynamicInsights2_webp from '../assets/dynamicinsights_2.webp';
import deviceImage_webp from '../assets/herosection.webp';
import falseVideo_webp from '../assets/falsevideo.webp';

export const headers: HeadersFunction = () => ({

});

export const meta: MetaFunction = () => {
  return [
    { title: 'Expressed Behaviour Analytics Platform' },
    {
      name: 'description',
      content: 'Welcome to the Expressed Behaviour Analytics Platform!',
    },
  ];
};

export async function loader({ request, params }: LoaderFunctionArgs) {
  return json('', {
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export async function action(/*request*/) {
  return redirect('/home/');
}

export default function Home() {
  const resp = useLoaderData<typeof loader>();
  const [billingType, setBillingType] = useState('yearly');

  const toggleBillingType = () => {
    setBillingType(billingType === 'monthly' ? 'yearly' : 'monthly');
  };

  const questions = [
    {
      title: 'Do I get free updates?',
      content:
        'With our Pro subscription, yes you can! This allows you to share your project with them as well as analytics and insights of each.',
      linktext: 'Click to learn more',
      linkref: '',
    },
    {
      title: 'Do I get free updates?',
      content:
        'With our Pro subscription, yes you can! This allows you to share your project with them as well as analytics and insights of each.',
      linktext: 'Click to learn more',
      linkref: '',
    },
    {
      title: 'Do I get free updates?',
      content:
        'With our Pro subscription, yes you can! This allows you to share your project with them as well as analytics and insights of each.',
      linktext: 'Click to learn more',
      linkref: '',
    },
    {
      title: 'Do I get free updates?',
      content:
        'With our Pro subscription, yes you can! This allows you to share your project with them as well as analytics and insights of each.',
      linktext: 'Click to learn more',
      linkref: '',
    },
  ];

  const pricing = [
    {
      title: 'Trial',
      subtitle: 'Have a go and test your superpowers.',
      feature: [
        '2 Users',
        '5 Files',
        'Public Share & Comments',
        'Chat Support',
        'New income apps',
      ],
      link: '',
      price: null,
    },
    {
      title: 'Basic',
      subtitle: 'For smaller teams or individuals getting started.',
      feature: [
        '3 Users',
        '20 video analyses/month',
        'Basic emotion detection',
        'Chat Support',
        'Email support',
      ],
      link: '',
      price: '£29',
    },
    {
      title: 'Pro',
      subtitle: 'Ideal for growing teams needing deeper insights.',
      feature: [
        'All the features of pro plan',
        '50 video analyses',
        'Advanced emotion detection',
        'Demographic breakdown',
        'Priority email support',
      ],
      link: '',
      price: '£99',
    },
  ];

  return (
    <div>
      <div id="home">
        <Header />
        <div id="homeContent">
          <div id="homeTitle">
            <div className="textContent">
              <h1 className="ctaHeader">
                New second-by-second insights into your customers' experience
                throughout their consumption journey.
              </h1>
              <p className="lightText">
                We monitor minute facial movements in the moment to highlight
                what drives changes in expressed emotion so you can better
                understand your customers' experience.
              </p>
              <div className="buttonContainer">
                <button className="sideButton">Get started for free</button>
                <button className="demoButton">
                  <CgPlayButtonO fontSize={30} /> View Demo
                </button>
              </div>
            </div>
            <img
              src={deviceImage_webp}
              alt="Insights"
              className="titleImage"
              onError={(e) => {
                e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
                e.currentTarget.src = deviceImage; // Fallback to the original JPG or PNG
              }}
            />

          </div>
          <hr className="midwide"></hr>
        </div>

        <Carousel />

        <p className="useCaseFooter">
          If you wish to discuss a particular use case please{' '}
          <a href="mailto:support@blueskeye.com">get in touch</a>
        </p>

        <hr className="midwide"></hr>
        <div className="videoSection">
          <h3>Save time and money whilst improving your results</h3>
          <img
            src={falseVideo_webp}
            alt="Demo video"
            style={{ width: '50%' }}
            onError={(e) => {
              e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
              e.currentTarget.src = falseVideo; // Fallback to the original JPG or PNG
            }}
          />

          <div className="container">
            <div className="imageSection">
              <img
                src={analyticsMix_webp}
                alt={"Description"}
                className="leftImage"
                style={{ width: '60%', height: 'auto' }}
                onError={(e) => {
                  e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
                  e.currentTarget.src = analyticsMix; // Fallback to the original JPG or PNG
                }}
              />
            </div>
            <div className="listSection">
              <div className="listContainer">
                <div className="listItem">
                  <div className="numberCircle">1</div>
                  <div className="textContent">
                    <h3 className="title">
                      Discover what works and what doesn't
                    </h3>
                    <p className="description">
                      Identify the key emotional triggers in a piece of content,
                      ensure they work as anticipated and deliver on your
                      business goals.
                    </p>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="listItem">
                  <div className="numberCircle">2</div>
                  <div className="textContent">
                    <h3 className="title">Quantify the qualitative</h3>
                    <p className="description">
                      Automatically aggregate data from numerous videos - making
                      the qualitative quantitative.
                    </p>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="listItem">
                  <div className="numberCircle">3</div>
                  <div className="textContent">
                    <h3 className="title">See the impact of the whole</h3>
                    <p className="description">
                      Continuous monitoring reduces the recency bias of a
                      generalised assessment allowing targeted action based on
                      precise insight.
                    </p>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="listItem">
                  <div className="numberCircle">4</div>
                  <div className="textContent">
                    <h3 className="title">Easy to interpret</h3>
                    <p className="description">
                      Easy to interpret visual data presentation allows ease of
                      interpretation, efficient dissemination of results and
                      rapid evidence-based action.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="midwide"></hr>
        <div className="contentContainer">
          <p className="blueParagraph">
            Our emotion AI technology is suitable for a range of user testing
            scenarios; these include response to an advert or online content;
            user surveys and use of IT based products and services.
          </p>
          <button className="sideButton">Get started for free</button>
        </div>
        <div className="dynamicinsightssection">
          <h3>Dynamic Insights</h3>
          <img
            src={dynamicInsights1_webp}
            alt="Summary metrics"
            style={{ width: '50%' }}
            onError={(e) => {
              e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
              e.currentTarget.src = dynamicInsights1; // Fallback to the original JPG or PNG
            }}
          />
          <img
            src={dynamicInsights2_webp}
            alt="Live metrics"
            style={{ width: '50%' }}
            onError={(e) => {
              e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
              e.currentTarget.src = dynamicInsights2; // Fallback to the original JPG or PNG
            }}
          />
        </div>
      </div>
      <div id="pricingSection">
        <h3 style={{ color: 'black' }}>Pricing & Plans</h3>
        <p className="pricingSubtitle">
          From individual users to small and global teams. Explore the best
          option for you
        </p>
        <div className="toggle-container">
          <button
            className={`toggle-button ${billingType === 'monthly' ? 'active' : ''}`}
            onClick={() => setBillingType('monthly')}
          >
            Bill Monthly
          </button>
          <button
            className={`toggle-button ${billingType === 'yearly' ? 'active' : ''}`}
            onClick={() => setBillingType('yearly')}
          >
            Bill Yearly
          </button>
        </div>
        <div className="rowArrangement" id="pricingRow">
          {pricing.map((e, i) => {
            return (
              <div
                className={
                  i == 1 ? 'pricingColumn tall' : 'pricingColumn medium'
                }
                key={`pricing-${e.title}`}
              >
                <div className="pricingHeader textAlignCenter">
                  <text className="pricingTitle boldText">{e.title}</text>
                  <div className="pricingSubtitleContainer">
                    <p
                      className={
                        i == 1
                          ? 'pricingSubtitle boldText highlight'
                          : 'pricingSubtitle boldText'
                      }
                    >
                      {e.subtitle}
                    </p>
                  </div>
                  <div className="price boldText">
                    <text className="blackText">
                      {e.price ? e.price : 'Free'}
                    </text>

                    {i == 1 && (
                      <>
                        <br></br>
                        <button className="pricingButton highlight noAction">
                          Save £50 a year
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className={
                    i == 1 ? 'featureColumn highlight' : 'featureColumn'
                  }
                >
                  {e.feature.map((feature, i) => {
                    if (i == e.feature.length - 1) {
                      return (
                        <div
                          className="feature boldText noBottomBorder"
                          key={`${e.title}-${feature}`}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                            }}
                          >
                            <FaCircleCheck className="featureCheck" />
                            <span> {feature}</span>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className="feature boldText"
                          key={`${e.title}-${feature}`}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                            }}
                          >
                            <FaCircleCheck className="featureCheck" />
                            <span> {feature}</span>
                          </div>
                        </div>
                      );
                    }
                  })}
                  <div className="pricingButtonContainer">
                    <button
                      className={
                        e.title == 'Basic'
                          ? 'pricingButton highlight'
                          : 'pricingButton'
                      }
                      onClick={() => {
                        return redirect(e.link);
                      }}
                    >
                      {e.title == 'Trial'
                        ? 'Signup for free'
                        : 'Get ' + e.title}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="enterpriseArrangement">
          <div className="enterpriseColumn" key={`pricing-Enterprise`}>
            <text
              className="pricingTitle white boldText"
              style={{ textAlign: 'center', marginBottom: '20px' }}
            >
              Enterprise
            </text>
            <text className="pricingSubtitle white">
              For large teams requiring extensive analysis.
            </text>

            <button
              style={{ marginBottom: '20px', width: '200px' }}
              className="pricingButton highlight"
              onClick={() => {
                return redirect('#');
              }}
            >
              Contact us
            </button>
          </div>
        </div>
        <div className="questionsContainer" id="questions">
          {questions.map((question, index) => {
            // Check if index is even to create a new row
            if (index % 2 === 0) {
              return (
                <div className="questionRow" key={index}>
                  {/* First question in the row */}
                  <div className="questionColumn">
                    <BsFillQuestionCircleFill style={{ color: '#1E3871' }} />{' '}
                    <b>{question.title}</b>
                    <p className="questionContent">{question.content}</p>
                    <a href={question.linkref}>{question.linktext}</a>
                  </div>
                  {/* Check if there's a next question for the second column */}
                  {questions[index + 1] && (
                    <div className="questionColumn">
                      <BsFillQuestionCircleFill style={{ color: '#1E3871' }} />{' '}
                      <b>{questions[index + 1].title}</b>
                      <p className="questionContent">
                        {questions[index + 1].content}
                      </p>
                      <a href={questions[index + 1].linkref}>
                        {questions[index + 1].linktext}
                      </a>
                    </div>
                  )}
                </div>
              );
            }
            return null; // Return null for odd indexes to avoid rendering extra rows
          })}
          <p>
            Haven't got your answer?{' '}
            <a href="mailto:support@blueskeye.com">Contact our support now</a>
          </p>
        </div>
      </div>

      <div className="missionSection">
        <hr className="midwide"></hr>
        <div className="missionLogo">
          <img
            src={bskLogo_webp}
            alt="Blueskeye logo"
            style={{ width: '100%', alignSelf: 'center' }}
            onError={(e) => {
              e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
              e.currentTarget.src = bskLogo; // Fallback to the original JPG or PNG
            }}
          />
        </div>
        <h1>
          BLUESKEYE AI are global leaders in using face data to understand
          expressed human behaviour. We have validated that our technology is
          better than the human eye for assessing changes in emotional
          responses.
        </h1>
        <p>
          In addition to commercial customers in the consumer, pharmaceutical
          and automotive spaces our technology is progressing through clinical
          trials to assure the UK health regulatory authorities of its efficacy.
        </p>
        <p>
          BLUESKEYE AI uses continuous multidimensional approach to continuously
          measure people's emotion throughout their experience of using your
          product or consuming your content. This means we can measure
          transitions between emotional state throughout. This better fits with
          the real human experience and provides richer and more nuanced
          insights.
        </p>
        <p>
          The technology has been in development for over 20 years, initially at
          the University of Nottingham and with Blueskeye since it was spun out
          of the University in 2019.
        </p>
        <p>
          The technology has been trained using our data bank of over XX billion
          data items and been proven to work effectively irrespective of
          apparent age, gender and ethnicity.
        </p>
        <hr className="midwide"></hr>
      </div>

      <div className="footerSection">
        <div className="contactSection">
          <div className="contactFormContainer">
            <form className="contactForm">
              <div className="inputGroup">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  className="inputField"
                />
              </div>
              <div className="inputGroup">
                <label htmlFor="industry">Industry:</label>
                <input
                  type="text"
                  id="industry"
                  name="industry"
                  required
                  className="inputField"
                />
              </div>
              <button type="submit" className="startTrialButton">
                Start Free Trial
              </button>
              <p>
                or <a href="#">Log In</a>
              </p>
            </form>
          </div>
          <div className="linksContainer">
            <div className="column">
              <h3 className="columnTitle">Pricing</h3>
              <ul className="linkList">
                <li>
                  <a className="footerLink" href="#link1">
                    Billing
                  </a>
                </li>
                <li>
                  <a className="footerLink" href="#link2">
                    FAQs
                  </a>
                </li>
                <li>
                  <a className="footerLink" href="#link3">
                    Compare Features
                  </a>
                </li>
              </ul>
            </div>
            <div className="column">
              <h3 className="columnTitle">Help Centre</h3>
              <ul className="linkList">
                <li>
                  <a className="footerLink" href="#link4">
                    Get support
                  </a>
                </li>
                <li>
                  <a className="footerLink" href="#link5">
                    Video tutorials
                  </a>
                </li>
                <li>
                  <a className="footerLink" href="#link6">
                    Live chat
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="midwide nopad"></hr>
        <Footer />
      </div>
    </div>
  );
}
